<template>
  <el-menu
    :default-active="activeIndexFun"
    class="el-menu"
    mode="horizontal"
    @select="handleSelect"
  >
    <!-- <el-menu-item><router-link to="/home">首页</router-link></el-menu-item> -->
    <el-menu-item   v-for="(item,index) in arr" :key="index" :index="`${index+1}`"   @click="linkproduct(item)"
       v-log="[item.name, '点击']">{{item.name}}</el-menu-item
    >
  </el-menu>
</template>
<script>
import {selectTagList}  from "api/home.js";
export default {
  data() {
    return {
       arr:[{name:"首页",path:"Home",num:0},

        {name:"品牌店铺",path:"ShopList",num:1}
      // {name:"复盛公专区",path:"ProductType",num:1},
      // {name:"药店必备",path:"ProductType",num:2},
      // {name:"经典畅销",path:"ProductType",num:3},
      // {name:"独家高毛",path:"ProductType",num:4},
      // {name:"医保专区",path:"ProductType",num:5},
      // // {name:"新品速递",path:"ProductType",num:6},

      // {name:"中药蜂蜜",path:"productcategory",num:6},
      // {name:"尾品特卖",path:"ProductType",num:7},
      // {name:"丸药学堂",path:"ProductType",num:8},
      // //  {name:"超值套餐",path:"SetMealList",num:8},
      //  {name:"药豆商城",path:"IntegralMallList",num:9},

      ],
      activeIndex: "0",
    };
  },
  methods: {
    handleSelect() {
      // key, keyPath
    },

// 除首页外的跳转
     jumpPath(item){
            // 跳标签列表页
                this.$router.push({name:"TagIdList",query:{tagId:item.tagId,type:item.num}})
          },
    linkproduct(item){
console.log(item)
      //(path,index)   item.path,item.num  
      if(item.num ===0){
        this.$router.push({name:item.path})
      }else if (item.num ===1){
        this.$router.push({name:item.path,query:{type:item.num}})
      }else{

        // if(item.name==="尾品特卖"){
        //   '1471321863751282689'


        // if(item.num===7){
        //   // 1522241773180104705    尾品特卖改
        //    this.$router.push({ name: "StorePage", query: { postId: '1522241773180104705'}})
        // }else{
        //   this.jumpPath(item)
        // }
        this.jumpPath(item)


        // if(item.path=="SetMealList"){
        //    this.$router.push({name:item.path,query:{type:item.num}})
        // }else if(item.path=="productcategory"){
        //    localStorage.setItem('newTooltip',JSON.stringify([{"name":"蜂蜜","show":true,"id":"1435058487840813057"}]));
        //    localStorage.setItem('clickVal','中药饮片');
        //    localStorage.setItem('clickID','1435058292428189698');
        //    this.$router.push({path:'productcategory?ids=1435058487840813057',query:{type:item.num}})
        // }
        // else{
        //   this.$router.push({name:item.path,query:{type:item.num}})
        // }
        // if(sessionStorage.getItem("parmesData")){
        //   sessionStorage.removeItem("parmesData")
        // }
      }
    }
  },
  computed: {
    activeIndexFun:function(){
      let activeindex="0"
      if(this.$route.query.type){
        activeindex=String(Number(this.$route.query.type)+1)
      }else{
        activeindex="1"
      }
      return activeindex
    }
  },
  created(){
      selectTagList().then(
        res=>{
          this.arr=[];
          let menuArr=res.data.data;
           console.log('menuArr',menuArr)
          this.arr.push({name:"首页",path:"Home",num:0},{name:"品牌店铺",path:"ShopList",num:1})
          menuArr.forEach((item,i)=> {
            this.arr.push({name:item.tagName,path:"tagIdList",num:i+2,tagId:item.id})
          });
          // console.log('reeeeee',this.arr)
        }
      )
  },
  
};
</script>

<style scoped lang="less">
  @import "~style/theme.less";
.el-menu a {
  text-decoration: none;
}
.el-menu--horizontal > .el-menu-item:hover{
  border-bottom: 2px solid @theme;
  color: @theme;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid @theme;
  color: @theme;
}
.el-menu--horizontal > .el-menu-item {
  color: #333333;
  height: 40px;
  line-height: 40px;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>
